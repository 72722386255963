import { Component, Input } from '@angular/core';
import { Moment, utc } from 'moment';
import { CapacityMetricItem } from 'api/types';
import { calculateUtilizationPercentage } from 'components/common/pools/utils/calculate-utilization-percentage';
import { EXCHANGE_FORMAT, FULL_MONTH_YEAR_FORMAT } from 'constants/date-formats';

export interface CapacityInfo {
  capacity: number;
  registrations: number;
  utilization: string;
  weekNumber?: number;
}

export type DailyMetricItem = CapacityMetricItem & {
  inCurrentMonth?: boolean;
}

/**
 *  Monthly variant of the appointments chart.
 */
@Component({
  selector: 'app-appointments-chart-monthly',
  templateUrl: './appointments-chart-monthly.component.html',
  styleUrls: [ './appointments-chart-monthly.component.scss' ]
})
export class AppointmentsChartMonthlyComponent {
  /**
   * An array of metrics with the critical flag set
   */
  @Input() public metricItems: CapacityMetricItem[] = [];

  /**
   * Currently selected month as a moment object
   */
  @Input() public selectedMonth: Moment = utc();

  /**
   * Metric items by the day instead of by the hour
   */
  public dailyMetricItems: DailyMetricItem[] = []

  /**
   * Metric item totals each week displayed on the calendar
   */
  public weeklyMetricItems: CapacityMetricItem[] = []

  /**
   * Metric item totals for the selected month
   */
  public monthlyMetricItems: CapacityInfo = {
    capacity: 0,
    registrations: 0,
    utilization: ''
  }

  public ngOnChanges(): void {
    // Build all metric times
    if (this.metricItems.length > 0) {
      this.buildDailyMetricItems();
      this.buildMonthlyMetricItems();
      this.buildWeeklyMetricItems();
    }
  }

  /**
   * Returns the display value for the selected month
   */
  public getMonthDisplay(): string {
    return utc(this.selectedMonth).format(FULL_MONTH_YEAR_FORMAT);
  }

  /**
   * Build a daily metric item
   */
  public buildDailyMetricItems(): void {
    // Remove time from date so we can sort the metric items by day
    let currentDate = utc(this.metricItems[ 0 ].timestamp).format(EXCHANGE_FORMAT);
    let currentDateCapacity: number | null = 0;
    let currentDateRegistrations = 0;
    const dailyMetricItems: DailyMetricItem[] = [];

    // Going through metricItems' hourly data and building an array that has metric items by the day
    this.metricItems.forEach((item, index) => {
      const itemDate = utc(item.timestamp).format(EXCHANGE_FORMAT);
      const isLastItem = index === this.metricItems.length - 1;
      const isCurrentDay = itemDate === currentDate;

      /**
       * Check if this metric item is for the same day as the day we're currently adding data up for. If not,
       * push the data we've added up into weeklyMetricItems and make this metric item's day the new current day
       */
      if (isCurrentDay) {
        currentDateCapacity = currentDateCapacity === null ? item.capacity : currentDateCapacity + (item.capacity || 0);
        currentDateRegistrations += item.registrations;
      } else {
        const inCurrentMonth = utc(currentDate).month() === this.selectedMonth.month();
        this.pushMetricItem(
          currentDateCapacity,
          currentDateRegistrations,
          currentDate,
          dailyMetricItems,
          inCurrentMonth
        );

        // Update current info to be the first item of the next days info
        currentDate = itemDate;
        currentDateCapacity = item.capacity;
        currentDateRegistrations = item.registrations;
      }

      /**
       * If this metric item is the last one, isCurrentDay is true so it will never hit the else block
       * that adds the current data to the daily array. This block adds it
       */
      if (isLastItem) {
        const inCurrentMonth = utc(currentDate).month() === this.selectedMonth.month();
        this.pushMetricItem(
          currentDateCapacity,
          currentDateRegistrations,
          currentDate,
          dailyMetricItems,
          inCurrentMonth
        );
      }
    });

    this.dailyMetricItems = dailyMetricItems;
  }

  public buildMonthlyMetricItems(): void {
    let monthlyCapacity = 0;
    let monthlyRegistrations = 0;

    /**
     * Going through dailyMetricItems' daily data and building a metric item that
     * has all data for the currently selected month
     */
    this.dailyMetricItems.forEach((item) => {
      const isCurrentMonth = utc(item.timestamp).month() === this.selectedMonth.month();
      if (isCurrentMonth) {
        monthlyCapacity += item.capacity || 0;
        monthlyRegistrations += item.registrations;
      }
    });
    this.monthlyMetricItems = {
      capacity: monthlyCapacity,
      registrations: monthlyRegistrations,
      utilization: calculateUtilizationPercentage(monthlyRegistrations, monthlyCapacity).toFixed(2) + '%'
    };
  }

  public buildWeeklyMetricItems(): void {
    // Get the week of the year for the first daily metric item, whatever week has Jan 1st is week 1
    let currentWeek = utc(this.dailyMetricItems[ 0 ].timestamp).week();
    let currentWeekCapacity = 0;
    let currentWeekRegistrations = 0;
    const weeklyMetricItems: CapacityMetricItem[] = [];

    // Going through 'dailyMetricItems' daily data and building an array that has metric items by the week
    this.dailyMetricItems.forEach((item, index) => {
      const itemWeek = utc(item.timestamp).week();
      const isLastItem = index === this.dailyMetricItems.length - 1;
      const isInCurrentWeek = itemWeek === currentWeek;

      /**
       * Check if the this daily metric item is in the week we're currently adding data up for. If not,
       * push the data we've added up into weeklyMetricItems and make this daily metric item's week the new current week
       */
      if (isInCurrentWeek) {
        currentWeekCapacity += item.capacity || 0;
        currentWeekRegistrations += item.registrations;
      } else {
        // Push the previous week's totals
        const prevWeekTimestamp = utc(item.timestamp).add(-7, 'days').format();
        this.pushMetricItem(currentWeekCapacity, currentWeekRegistrations, prevWeekTimestamp, weeklyMetricItems);
        currentWeek = itemWeek;
        currentWeekCapacity = item.capacity || 0;
        currentWeekRegistrations = item.registrations;
      }

      /**
       * If this daily metric item is the last one, isCurrentDay is true so it will never hit the else block
       * that adds the current data to the weekly array. This block adds it
       */
      if (isLastItem) {
        const thisWeekTimestamp = utc(item.timestamp).day(0).format();
        this.pushMetricItem(currentWeekCapacity, currentWeekRegistrations, thisWeekTimestamp, weeklyMetricItems);
      }
    });

    this.weeklyMetricItems = weeklyMetricItems;
  }

  public pushMetricItem(
    capacity: number | null,
    registrations: number,
    timestamp: string,
    array: CapacityMetricItem[],
    inCurrentMonth?: boolean): void {
    const basicData = {
      capacity,
      registrations,
      timestamp
    };
    const allData = inCurrentMonth !== null ? { ...basicData, inCurrentMonth } : basicData;
    array.push(allData);
  }
}

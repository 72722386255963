<mat-form-field
  [appearance]="appearance"
>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list
    #dateChipList
    [required]="required"
    [formControl]="control"
    [errorStateMatcher]="errorMatcher"
  >
    <mat-chip
      *ngFor="let date of control.value"
      [removable]="isDateRemovable(date)"
      (removed)="removeDate(date)"
      color="primary"
    >
      {{formatDate(date)}}
      <button
        *ngIf="isDateRemovable(date)"
        matChipRemove
        attr.aria-label="{{ 'label.aria.remove' | vueTranslate: formatDate(date) | async }}"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip>
    <input
      matInput
      #dateInput
      [placeholder]="placeholder"
      [matDatepicker]="datePicker"
      [matDatepickerFilter]="includeDates"
      [matChipInputFor]="dateChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [min]="getMinDate()"
      [max]="maxDate"
      (dateChange)="selectDate($event)"
      (click)="datePicker.open()"
    >
  </mat-chip-list>
  <mat-datepicker
    #datePicker
    panelClass="datepicker-calendar"
  ></mat-datepicker>
</mat-form-field>

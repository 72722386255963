<div
  [ngClass]="{
    'date-selection-filter': true,
    'group-position-first': groupPosition === 'first',
    'group-position-last': groupPosition === 'last',
    'customStyleFromCapacityUpload': customStyle
  }"
>
  <button
    mat-button
    type="button"
    [attr.aria-label]="backButtonTranslationKey | translate | async"
    (click)="backwardClicked()"
  >
    <vue-arrow direction="backward"></vue-arrow>
  </button>
  <app-date-selection-label
    [timescale]="timescale"
    [startDate]="startDate"
    (dateChange)="manualDateChange($event)"
    [customStyle]="customStyle"
  ></app-date-selection-label>
  <button
    mat-button
    type="button"
    [attr.aria-label]="forwardButtonTranslationKey | translate | async"
    (click)="forwardClicked()"
  >
    <vue-arrow direction="forward"></vue-arrow>
  </button>
</div>

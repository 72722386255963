<form id="form" [formGroup]="addPoolForm" (ngSubmit)="validatePool()" autocomplete="off">
  <vue-text-input
    label="{{ 'title.poolName' | translate | async }}"
    formControlName="poolName"
    [required]="true"
    [errorMatcher]="poolNameErrorMatcher"
    errorMessage="{{ poolNameErrorMessageKey() | translate | async }}"
  ></vue-text-input>
  <app-auto-complete
    label="{{ 'title.clients' | translate | async }}"
    formControlName="clientIds"
    [required]="true"
    [retrieveAutoCompleteList]="getClients.bind(this)"
    [getItemDisplay]="getClientName"
    [getItemIdentifier]="getId"
    [isValid]="getClientIsValidFlag" 
    [errorMatcher]="suppressErrorState"
  ></app-auto-complete>
  <app-auto-complete
    label="{{ 'title.exams' | translate | async }}"
    formControlName="examIds"
    [retrieveAutoCompleteList]="getExams.bind(this)"
    [getItemDisplay]="getExamName"
    [getItemIdentifier]="getId"
    [isValid]="getExamIsValidFlag" 
  ></app-auto-complete>
  <vue-select
    label="{{ 'title.queue' | translate | async }}"
    formControlName="queueId"
    [required]="true"
    [options]="queues"
    [getOptionDisplay]="getQueueDisplay"
    [errorMatcher]="suppressErrorState"
  ></vue-select>
  <vue-text-input
    label="{{ 'title.candidateCode' | translate | async }}"
    formControlName="candidateCode"
    [required]="false"
  ></vue-text-input>
  <div class="pool-setting">
    <app-pool-restriction formControlName="isRestricted"></app-pool-restriction>
  </div>
  <h4>{{ 'title.date' | translate | async }}</h4>
  <div class="two-columns">
    <vue-datepicker
      label="{{ 'label.placeholder.startPoolRange' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}"
      formControlName="startDate"
      [required]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [minDateIsToday]="true"
      [maxDate]="addPoolForm.controls.endDate.value"
      [errorMessage]="addPoolForm.controls.startDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
    <vue-datepicker
      label="{{ 'label.placeholder.endPoolRange' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}"
      formControlName="endDate"
      [required]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [minDate]="addPoolForm.controls.startDate.value"
      [minDateIsToday]="true"
      [errorMessage]="addPoolForm.controls.endDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
  </div>
  <app-pool-days-of-week formControlName="daysOfWeek"></app-pool-days-of-week>
  <app-pool-days-of-week-with-restriction formControlName="daysOfWeekWithRestriction" [restrictedDays]="selectedDayNumbers"></app-pool-days-of-week-with-restriction>
  <vue-datepicker-chiplist
    label="{{ 'label.editPool.dateExceptions' | translate | async }}"
    formControlName="dateExceptions"
    ariaLabelRemove="{{ 'label.remove' | translate | async }}"
    [required]="false"
    [minDate]="addPoolForm.controls.startDate.value"
    [minDateIsToday]="true"
    [maxDate]="addPoolForm.controls.endDate.value"
    [filterDates]="filterDatesForExceptions.bind(this)"
  ></vue-datepicker-chiplist>
  <vue-datepicker-chiplist
    label="{{ 'label.editPool.dateAdditions' | translate | async }}"
    formControlName="dateAdditions"
    ariaLabelRemove="{{ 'label.remove' | translate | async }}"
    [required]="false"
    [minDate]="addPoolForm.controls.startDate.value"
    [minDateIsToday]="true"
    [maxDate]="addPoolForm.controls.endDate.value"
    [filterDates]="filterDatesForAdditions.bind(this)"
  ></vue-datepicker-chiplist>
  <app-pool-schedule formControlName="poolSchedule"></app-pool-schedule>
  <div class="pool-setting">
    <app-pool-release
      formControlName="poolRelease"
      [poolEndDate]="addPoolForm.controls.endDate.value"
    ></app-pool-release>
  </div>
</form>

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Moment, utc } from 'moment';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslationKey } from 'pipes/translate.pipe';
import { Timescale } from 'types/AppointmentFilters';
import { Direction } from 'types/Direction';
import { getFirstDayOfWeek } from 'utils/get-first-day-of-week';

/**
 * Filter component that allows users to select a date based on the timescale of the filter.
 */
@Component({
  selector: 'app-date-selection-filter',
  templateUrl: './date-selection-filter.component.html',
  styleUrls: [ './date-selection-filter.component.scss' ]
})
export class DateSelectionFilterComponent {
  @Input() public timescale?: Timescale | null = Timescale.daily

  /**
   * The start date in string format YYYY-MM-DD, eg '2021-03-28
   */
  @Input() public startDate?: string | null

  /**
   * Event fired when startDate is changed
   */
  @Output() public change = new EventEmitter<{ value: string }>();

  /**
   * Optionally group with neighboring filters
   */
  @Input() public groupPosition?: 'first' | 'last'
  /**
   * Custom styling
   */
  @Input() public customStyle: boolean = false;
  /**
   * Move to next interval
   */
  public forwardClicked(): void {
    this.increment('forward');
  }

  /**
   * Move to previous interval
   */
  public backwardClicked(): void {
    this.increment('backward');
  }

  /**
   * Set back button aria label based on timescale
   */
  public get backButtonTranslationKey(): TranslationKey {
    switch (this.timescale) {
      case 'weekly':
        return 'label.aria.selectPreviousWeek';
      case 'monthly':
        return 'label.aria.selectPreviousMonth';
      case 'outagesmonthly':
        return 'label.aria.selectPreviousMonth';
      case 'outagesyearly':
        return 'label.aria.selectPreviousYear';
      default:
        return 'label.aria.selectPreviousDay';
    }
  }

  /**
   * Set forward button aria label based on timescale
   */
  public get forwardButtonTranslationKey(): TranslationKey {
    switch (this.timescale) {
      case 'weekly':
        return 'label.aria.selectNextWeek';
      case 'monthly':
        return 'label.aria.selectNextMonth';
      case 'outagesmonthly':
        return 'label.aria.selectNextMonth';
      case 'outagesyearly':
        return 'label.aria.selectNextYear';
      default:
        return 'label.aria.selectNextDay';
    }
  }

  /**
   * Process manual date change from `DateSelectionLabelComponent`
   *
   * Converts the date to the relative date based on the timescale
   */
  public manualDateChange(newDate: Moment): void {
    let date = newDate.clone();

    if (this.timescale === Timescale.monthly || this.timescale === Timescale.outagesmonthly) {
      // Use beginning of month
      date = date.startOf('month');
    } else if (this.timescale === Timescale.weekly) {
      // Use sunday of the week
      date = getFirstDayOfWeek(date);
    }

    this.change.emit({ value: date.format(EXCHANGE_FORMAT) });
  }

  /**
   * Calculate next interval based on timescale
   */
  private increment(direction: Direction): void {
    if (!this.startDate) {
      return;
    }
    const momentDate = utc(this.startDate, EXCHANGE_FORMAT);

    /**
     * Flip the sign of the number based on the direction
     *
     * @param n the number to flip
     * @returns flipped the number with the appropriate sign
     */
    function polarize(n: number): number {
      return direction === 'forward' ? n : n * -1;
    }

    let newDate: Moment;

    switch (this.timescale) {
      case 'weekly':
        newDate = momentDate.add(polarize(7), 'days');
        break;
      case 'monthly':
        newDate = momentDate.set('month', momentDate.month() + polarize(1)).startOf('month');
        break;
      case 'outagesmonthly':
        newDate = momentDate.set('month', momentDate.month() + polarize(1)).startOf('month');
        break;
      case 'outagesyearly':
        newDate = momentDate.set('year', momentDate.year() + polarize(1)).startOf('year');
        break;
      default:
        newDate = momentDate.add(polarize(1), 'day');
    }

    this.change.emit({ value: newDate.format(EXCHANGE_FORMAT) });
  }
}

/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { FocusMonitor } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { Moment, utc } from 'moment';
import { DISPLAY_DAY_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { VueDateAdapter } from '../../utilities/vue-datepicker-common/vue-datepicker-adapter';
import { VueFormFieldBaseComponent } from '../../utilities/vue-form-field-base/vue-form-field-base.component';

/**
 * Component to display a list of "chips" in the datepicker
 */
@Component({
  selector: 'vue-datepicker-chiplist',
  templateUrl: './vue-datepicker-chiplist.component.html',
  styleUrls: [ './vue-datepicker-chiplist.component.scss' ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: VueDatePickerChipListComponent, multi: true },
  ]
})
export class VueDatePickerChipListComponent extends VueFormFieldBaseComponent implements AfterViewInit, OnDestroy {
  /**
   * Minimum date selectable in datepicker
   */
  @Input() public minDate: Moment | null = null;

  /**
   * Maximum date selectable in datepicker
   */
  @Input() public maxDate: Moment | null = null;

  /**
   * Minimum date should be today
   */
  @Input() public minDateIsToday?: boolean;

  /**
   * Past dates should not be selectable
   */
  @Input() public disablePastDates?: boolean;

  /**
   * Method that takes in an individual date, if it result is true that date is a valid selection
   */
  @Input() public filterDates?: (date: Moment) => boolean;

  /**
   * Aria label applied to each chips remove button, along with the selected date
   */
  @Input() public ariaLabelRemove?: string;

  /**
   * Reference to the input element associated with the datepicker
   */
  @ViewChild('dateInput') public dateInput!: ElementRef<HTMLInputElement>;

  /**
   * Reference to the material input associated with the datepicker
   */
  @ViewChild(MatInput) public matInput!: MatInput;

  /**
   * Reference to the datepicker
   */
  @ViewChild(MatDatepicker) public datePicker!: MatDatepicker<VueDateAdapter>;

  /**
   * The list of key codes that will trigger a chipEnd event.
   */
  public separatorKeysCodes: number[] = [ ENTER ];

  // Test cases need injected container
  public constructor(
  @Inject(ControlContainer) controlContainer: ControlContainer,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef
  ) {
    super(controlContainer);
  }

  public ngAfterViewInit(): void {
    // Only open datepicker if input was focused by keyboard
    this.focusMonitor.monitor(this.dateInput)
      .subscribe((origin) => {
        if (origin === 'keyboard' || origin === 'mouse') {
          this.datePicker.open();
          this.cdr.detectChanges();
        }
      });
  }

  /**
   * Remove monitor from `dateInput`
   */
  public ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.dateInput);
  }

  /**
   * Remove the date from the chiplist
   */
  public removeDate(removeDate: string): void {
    const selectedDates: string[] = this.control.value || [];
    const index = selectedDates.findIndex((date) => date === removeDate);
    if (index !== -1) {
      selectedDates.splice(index, 1);
      this.control.setValue(selectedDates);
    }
  }

  /**
   * Add the date to the chiplist
   */
  public selectDate(event: MatDatepickerInputEvent<Moment>): void {
    if (event.value) {
      this.control.setValue([
        ...this.control.value,
        event.value.format(EXCHANGE_FORMAT)
      ]);
    }

    this.matInput.value = '';
    this.datePicker.close();
  }

  /**
   * Format chiplist date display
   */
  public formatDate(date: string): string {
    return utc(date, EXCHANGE_FORMAT).format(DISPLAY_DAY_FORMAT);
  }

  /**
   * Determines if date is enabled or disabled in datepicker
   * Called against each date in a month
   *
   * @param date date from datepicker
   * @returns true if date is enabled
   */
  public includeDates = (date: Moment | null): boolean => {
    if (!date) {
      return true;
    } else if (this.control.value.includes(date.format(EXCHANGE_FORMAT))) {
      return false;
    } else if (this.filterDates) {
      return this.filterDates(date);
    }
    return true;
  }

  /**
   * Chooses Minimum date based on inputs
   *
   * @returns min date of datepicker
   */
  public getMinDate(): Moment | null {
    if (this.minDate) {
      return this.minDate;
    } else if (this.minDateIsToday) {
      return utc();
    }
    return null;
  }

  /**
   * Based on disablePastDates, does not a let a user
   * remove a past date
   *
   * @param date each date selected
   * @returns if date should be removable
   */
  public isDateRemovable(date: string): boolean {
    return this.disablePastDates ? !utc(date, EXCHANGE_FORMAT).isBefore(utc()) : true;
  }
}

<form id="form" [formGroup]="editPoolForm" (ngSubmit)="validatePoolName()" autocomplete="off">
  <h4>{{ 'title.poolName' | translate | async }}</h4>
  <vue-text-input label="{{ 'title.poolName' | translate | async }}" formControlName="poolName" [required]="true"
    [errorMatcher]="poolNameErrorMatcher" errorMessage="{{ poolNameErrorMessageKey() | translate | async }}">
  </vue-text-input>
   <app-auto-complete
    label="{{ 'title.clients' | translate | async }}"
    formControlName="clientIds"
    [required]="true"
    [retrieveAutoCompleteList]="getClients.bind(this)"
    [getItemDisplay]="getClientName"
    [getItemIdentifier]="getId"
    [errorMatcher]="suppressErrorState"
    [isValid]="getClientIsValidFlag" 
  ></app-auto-complete>
  <app-auto-complete
    label="{{ 'title.exams' | translate | async }}"
    formControlName="examIds"
    [retrieveAutoCompleteList]="getExams.bind(this)"
    [getItemDisplay]="getExamName" 
    [getItemIdentifier]="getId"
    [isValid]="getExamIsValidFlag"
  ></app-auto-complete>
  <div class="pool-setting">
    <app-pool-restriction formControlName="isRestricted"></app-pool-restriction>
  </div>
  <h4>{{ 'title.date' | translate | async }}</h4>
  <div class="two-columns">
    <vue-datepicker
      label="{{ 'label.placeholder.startPoolRange' | translate | async }}"
      formControlName="startDate"
      [required]="true"
      [minDateIsToday]="true"
      [maxDate]="editPoolForm.controls.endDate.value"
      [errorMatcher]="datePickerErrorMatcher"
      [errorMessage]="editPoolForm.controls.startDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
    <vue-datepicker
      label="{{ 'label.placeholder.endPoolRange' | translate | async }}"
      formControlName="endDate"
      [required]="true"
      [minDate]="minDate"
      [minDateIsToday]="true"
      [errorMatcher]="datePickerErrorMatcher"
      [errorMessage]="editPoolForm.controls.endDate.errors | datepickerError | translate | async"
    ></vue-datepicker>
  </div>
  <app-pool-days-of-week formControlName="daysOfWeek"></app-pool-days-of-week>
  <app-pool-days-of-week-with-restriction formControlName="daysOfWeekWithRestriction" [restrictedDays]="selectedDayNumbers"></app-pool-days-of-week-with-restriction>
  <vue-datepicker-chiplist
    label="{{ 'label.editPool.dateExceptions' | translate | async }}"
    placeholder="{{ 'placeholder.datepicker' | translate | async }}"
    formControlName="dateExceptions"
    ariaLabelRemove="{{ 'label.remove' | translate | async }}"
    [required]="false"
    [minDate]="minDate"
    [maxDate]="editPoolForm.controls.endDate.value"
    [filterDates]="filterDatesForExceptions.bind(this)"
    [disablePastDates]="true"
  ></vue-datepicker-chiplist>
  <vue-datepicker-chiplist
    label="{{ 'label.editPool.dateAdditions' | translate | async }}"
    placeholder="{{ 'placeholder.datepicker' | translate | async }}"
    formControlName="dateAdditions"
    [required]="false"
    [minDate]="minDate"
    [maxDate]="editPoolForm.controls.endDate.value"
    [filterDates]="filterDatesForAdditions.bind(this)"
    [disablePastDates]="true"
  ></vue-datepicker-chiplist>
  <div class="pool-setting">
    <app-pool-release
      formControlName="poolRelease"
      [poolEndDate]="editPoolForm.controls.endDate.value"
    ></app-pool-release>
  </div>
</form>

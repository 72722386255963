import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { AfterViewChecked, ApplicationRef, Component, ComponentFactoryResolver, HostListener, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Moment, utc } from 'moment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import { CapacityMetricItem, GetMetricsParameters, GetMetricsResponse, Interval, Queue } from 'api/types';
import { COLUMN_WIDTH_REMS, FIRST_COLUMN_WIDTH_REMS } from 'components/charts/utils/constants';
import { PageBodyComponent } from 'components/common/page-body/page-body.component';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { INTERVAL_15MIN, INTERVAL_1DAY } from 'constants/intervals';
import { MetricsService } from 'services/api/metrics.service';
import { QueuesService } from 'services/api/queues.service';
import { AppointmentsFiltersService } from 'services/appointments-filters.service';
import { AppointmentsInlineCapacityService } from 'services/appointments-inline-capacity.service';
import { PageStatusService } from 'services/status/page-status.service';
import { TimezoneService } from 'services/timezone.service';
import { Timescale } from 'types/AppointmentFilters';
import { FlaggedCapacityMetricItem } from 'types/FlaggedCapacityMetricItem';
import { ERROR, LOADING, SUCCESS } from 'types/RequestStatus';
import { Timezone } from 'types/Timezone';
import { addCriticalFlags } from 'utils/add-critical-flags';
import { getFirstDayOfWeek } from 'utils/get-first-day-of-week';
import { ComponentCanDeactivate } from '../../guards/unsaved-changes.guard';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { MatDialog } from '@angular/material/dialog';

/**
 *  Appointments page
 */
@Component({
  selector: 'app-appointments-page',
  templateUrl: './appointments-page.component.html',
})
export class AppointmentsPageComponent implements OnInit, OnDestroy, ComponentCanDeactivate, AfterViewChecked {
  /**
   * Page body reference to reset loading state if queues were to fail
   */
  @ViewChild(PageBodyComponent) public pageBodyComponent?: PageBodyComponent;

  /**
   * Reference to the portal that contains the UnsavedChangesPortal
   */
  @ViewChild(CdkPortal) public unsavedChangesPortal?: CdkPortal;

  /**
   * Selected timezone from within the appointment filters
   */
  public timezone: Timezone | null = null;

  /**
   * Metric items used to display capacity & registration details for the dates in context
   */
  public metricItems: CapacityMetricItem[] = [];

  /**
   * Metric items that also include a flagged attribute if the utilization of an interval exceeds a threshold.
   *
   * See `addCriticalFlags` method.
   */
  public criticalMetricItems: FlaggedCapacityMetricItem[] = [];

  /**
   * Width of the table & chart columns
   */
  public columnWidthRems = COLUMN_WIDTH_REMS;

  /**
   * Width of the y-axis column for the table & chart
   */
  public firstColumnWidthRems = FIRST_COLUMN_WIDTH_REMS;

  /**
   * Selected interval of the data shown
   */
  public interval: Interval = INTERVAL_15MIN;

  /**
   * An array of dates representing the range days in weekly or monthly views
   */
  public days: string[] = []

  /**
   * The current month the user is viewing, if in month view
   */
  public selectedMonth: Moment = utc();

  /**
   * Flag to display the inline error message.
   */
  public showNetworkError = false;

  /**
   * Internal variable that tracks the page loading state
   */
  public pageLoading = false;

  /**
   * Current list of available queues
   */
  public queues: Queue[] = [];

  /**
   * Fetches all metrics needed for the page.
   * Calls are made based on the currently selected filters.
   * Trigger a new data refresh with .next().
   * Cancels previous calls.
   */
  public refreshMetricsData$ = new Subject<void>();

  /**
   * Whether there are any outstanding edits
   */
  private unsavedChanges = false;

  /**
   * Terminate subscriptions
   */
  private destroyed$ = new Subject();

  /**
   * The current parameters used for the `getMetrics` API
   */
  private getMetricParams?: GetMetricsParameters;

  /**
   * Reference to the outlet created by adding the UnsavedChangesBanner to the portal
   */
  private unsavedChangesOutlet?: DomPortalOutlet;

  public constructor(
    private getMetricsService: MetricsService,
    private filterService: AppointmentsFiltersService,
    private inlineCapacityService: AppointmentsInlineCapacityService,
    private pageStatusService: PageStatusService,
    private queuesService: QueuesService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    timezoneService: TimezoneService,
    private dialog: MatDialog
  ) {
    // Fetch queues at the page level because they're used both for filters & page content
    this.fetchQueues();

    timezoneService.selectedTimezone$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tz) => {
        this.timezone = tz;
      });

    pageStatusService.status$.pipe(
      takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.pageLoading = status === LOADING;
        /* if (this.pageLoading) {
          this.dialog.open<
            LoadingDialogComponent,
            LoadingDialogInput
          >(LoadingDialogComponent, {
            ...loadingDialogConfig,
            data: {
              title: 'title.loading',
              subtitle: 'subtitle.pleaseWait',
            },
          });
        } else {
          this.dialog.closeAll();
        } */
      });
  }

  // Always start the weekly range with the first day of the week
  private static getWeeklyParams(params: GetMetricsParameters): GetMetricsParameters {
    const startDate = getFirstDayOfWeek(utc(params.startDate)).format(EXCHANGE_FORMAT);

    return { ...params, startDate };
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  public canDeactivate(): Observable<boolean> | boolean {
    return !this.unsavedChanges;
  }

  /**
   * Set up subscriptions to get page data
   * Either when params (filters) change or inline capacity is edited
   */
  public ngOnInit(): void {
    this.filterService.params$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((updated) => {
        this.getMetricParams = updated;

        this.refreshMetricsData$.next();
      });

    this.inlineCapacityService.editSubmissionStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.showNetworkError = false;

        switch (status) {
          case SUCCESS:
            this.refreshMetricsData$.next();
            break;
          case ERROR:
            this.showNetworkError = true;
            break;
        }
      });

    // Use the number of edited controls to determine whether there are outstanding changes
    this.inlineCapacityService.numberOfEditedControls$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((num) => {
        this.unsavedChanges = num > 0;
      });

    // Update metrics
    this.refreshMetricsData$
      .pipe(takeUntil(this.destroyed$))
      .pipe(switchMap(() => { // switchMap will cancel any in-flight API requests
        const params = this.paramsForTimescale();

        // selected queue id is needed for metrics call
        if (!params || !params.queueId) {
          return EMPTY;
        }

        this.pageStatusService.loading(() => {
          // Reset loading count so full page loader is shown
          this.pageBodyComponent?.resetLoadingCount();
          this.refreshMetricsData$.next();
        });

        // Perform the call!
        return this.getMetricsService.getMetrics(params);
      })).subscribe((res) => {
        this.getMetricsSuccess(res);
      }, () => {
        this.pageStatusService.error();
      });
  }

  /**
   * Terminate subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    this.unsavedChangesOutlet?.detach();
  }

  /**
   * After every view update, check if unsavedChangesPortal should be added to the page
   */
  public ngAfterViewChecked(): void {
    const portalDestination = document.querySelector('#unsaved-changes-portal');

    /*
     * Add unsaved changes banner should be added when:
     * 1. It doesn't already exist
     * 2. timescale is daily
     * 3. portal destination can be found
     */
    const hasAttached = this.unsavedChangesOutlet?.hasAttached();
    const isDaily = this.timescale() === Timescale.daily;
    if (!hasAttached && isDaily && portalDestination) {
      this.unsavedChangesOutlet = new DomPortalOutlet(
        portalDestination,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
      );

      this.unsavedChangesOutlet.attach(this.unsavedChangesPortal);
    } else if (hasAttached && !isDaily) {
      // If unsaved changes banner is attached and timescale is no longer daily, detach it
      this.unsavedChangesOutlet?.detach();
    }
  }

  /**
   * Get inline error message from `inlineCapacityService`
   */
  public get inlineErrorMessage(): string {
    return this.inlineCapacityService.editSubmissionError?.message || '';
  }

  /**
   * Trigger another submission of inline capacity edits.
   * Useful when a submission fails and we want to provide a "retry" action.
   */
  public retryInlineSave(): void {
    this.inlineCapacityService.saveEditedCapacities();
  }

  /**
   * @returns true if interval is '15min'
   */
  public intervalIs15min(): boolean {
    return this.interval === INTERVAL_15MIN;
  }

  /**
   * Determine the current timescale based on numberOfDays
   *
   * @returns 'daily' | 'weekly' | 'monthly'
   */
  public timescale(): Timescale {
    const numberOfDays = this.getMetricParams?.numberOfDays || 0;
    if (numberOfDays === 7) {
      return Timescale.weekly;
    } else if (numberOfDays > 7) {
      return Timescale.monthly;
    }
    return Timescale.daily;
  }

  /**
   * Success handler for getMetrics API
   */
  private getMetricsSuccess(res: GetMetricsResponse): void {
    const { metrics, interval } = res;

    // Combine items from all days
    this.metricItems = metrics.reduce<CapacityMetricItem[]>((acc, metric) => {
      return acc.concat(metric.items);
    }, []);
    this.criticalMetricItems = addCriticalFlags(this.metricItems);

    this.days = metrics.map((metric) => metric.date);
    this.interval = interval;

    this.pageStatusService.success();
  }

  /**
   * Fetches queues for filters & metrics
   */
  private fetchQueues(): void {
    this.pageStatusService.loading(() => {
      // Reset loading count so full page loader is shown
      this.pageBodyComponent?.resetLoadingCount();
      this.fetchQueues();
    });

    this.queuesService.getQueues()
      .pipe(take(1)).subscribe((queues) => {
        this.queues = queues;

        /*
         * Do not switch to success state of page, `fetchMetrics$.next()`
         * will still need to be run once filter params are updated
         * and will handle the success state
         *
         * Queues passed to `AppointmentFilters` -> updates params -> Triggers `fetchMetrics$.next()`
         */
      }, () => {
        this.pageStatusService.error();
      });
  }

  /**
   * Returns the params for the currently selected timescale
   */
  private paramsForTimescale(): GetMetricsParameters | null {
    if (!this.getMetricParams || this.getMetricParams.numberOfDays === 0) {
      return null;
    }

    switch (this.timescale()) {
      case Timescale.monthly:
        return this.getMonthlyParams(this.getMetricParams);
      case Timescale.weekly:
        return AppointmentsPageComponent.getWeeklyParams(this.getMetricParams);
      default:
        return this.getMetricParams; // Daily uses the parameters straight from the filterService
    }
  }

  /**
   * Calculates the API parameters for `getMetrics` for a given month.
   *
   * To fill the full calendar view, days outside of the current month could be included.
   */
  private getMonthlyParams(params: GetMetricsParameters): GetMetricsParameters {
    const monthStart = utc(params.startDate).startOf('month');
    const monthEnd = utc(params.startDate).endOf('month');

    this.selectedMonth = monthStart.clone();

    // Figuring out how many days we need before and after the month to fill all calendar cells
    const daysBeforeNeeded = monthStart.day();
    const daysAfterNeeded = 6 - monthEnd.day();

    // Adding and subtracting appropriate amount of days from month start and end
    const newStart = monthStart.subtract(daysBeforeNeeded, 'days');
    const newEnd = monthEnd.add(daysAfterNeeded, 'days');

    // Getting correct number of days to fill the calendar
    const numDays = newEnd.diff(newStart, 'days') + 1;

    return {
      ...params,
      startDate: newStart.format(EXCHANGE_FORMAT),
      numberOfDays: numDays,
      interval: INTERVAL_1DAY,
    };
  }
}

import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { TemplatePageService } from 'services/template-page.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { ERROR, LOADING, RequestStatus } from 'types/RequestStatus';
import { VueDrawerComponent, VueDrawerConfig } from 'vue/components/vue-drawer/vue-drawer.component';
import { DrawerHeaderComponent } from '../drawer-header/drawer-header.component';
import { AddTemplateState, TemplateAction, TemplateStep, TemplateSteps } from './add-template-state/add-template-state.service';
import { UnsavedChangesDialogService } from 'services/unsaved-changes-dialog.service';

/**
 *  Drawer that houses the template flow. Can handle adding, editing or duplicating a template
 */
@Component({
  selector: 'app-add-template-drawer',
  styleUrls: [ './add-template-drawer.component.scss' ],
  templateUrl: './add-template-drawer.component.html',
})
export class AddTemplateDrawerComponent implements OnDestroy {
  /**
   * Reference to VUE drawer component
   */
  @ViewChild('drawer') public drawer!: VueDrawerComponent;

  /**
   * Reference to dialog content to handle scrolling
   */
  @ViewChild('dialogContent') public dialogContent?: ElementRef<HTMLDivElement>;

  /**
   * Reference to the drawer header so it can be focused when navigating between steps
   */
  @ViewChild(DrawerHeaderComponent) public drawerHeader?: DrawerHeaderComponent;

  /**
   * Custom config passed to drawer component
   */
  public drawerConfig: VueDrawerConfig = {
    disableClose: true,
  }

  /**
   * The current step of the flow
   */
  public step: TemplateStep;

  /**
   * All steps in the flow
   */
  public steps = TemplateSteps;

  /**
   * First step index is -1 so that day indexes align with their day of the week
   * step: form = -1
   * step: sunday = 0
   * step: saturday = 7
   *
   * @returns stepNumber the index of the current step
   */
  public get stepNumber(): number {
    return this.steps.indexOf(this.step) - 1;
  }

  /**
   * The loading status of the drawer
   */
  public status: RequestStatus = 'initial';

  /**
   * Terminate all subscriptions when complete
   */
  private destroyed$ = new Subject();

  private enableNextBtnManually = false;

  public constructor(
    private state: AddTemplateState,
    private templatePageService: TemplatePageService,
    private drawerStatusService: DrawerStatusService,
    private unsavedChangesDialogService: UnsavedChangesDialogService
  ) {
    // Set initial step before below subscription handle subsequent updates
    this.step = this.state.step$.value;

    // Subscribe to step updates
    this.state.step$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((step) => {
        this.step = step;
        this.scrollToTop();
        this.drawerHeader?.focusOnTitle();
      });

    // Close drawer after a successful submission
    this.state.templateSuccess$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.drawer.close();
        this.reset();
      });

    // Open drawer to edit an existing template
    this.templatePageService.editTemplate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((template) => {
        this.state.updateFormWithEditValues(template);
        this.openDrawer({ type: 'edit', id: template.id, status: template.status });
      });

    // Open drawer to duplicate an existing template
    this.templatePageService.duplicateTemplate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((template) => {
        this.state.updateFormWithEditValues(template);
        this.openDrawer({ type: 'duplicate' });
      });

    // Open drawer to duplicate an existing template
    this.templatePageService.activeTemplate$
    .pipe(takeUntil(this.destroyed$))
    .subscribe((template) => {
      this.state.updateFormWithEditValues(template);
      this.openDrawer({ type: 'active', id: template.id, status: 'active' });
    });

    // Update local status of the drawer
    this.drawerStatusService.status$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.status = status;

        // Scroll to top when errors are displayed
        if (status === ERROR) {
          this.scrollToTop();
        }
      });

    this.state.enableNextBtnManually$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag) => {
        this.enableNextBtnManually = flag;
      });
  }

  /**
   * Clean up subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * @returns type of template action
   */
  public get type(): 'new' | 'edit' | 'duplicate' | 'active' {
    return this.state.typeOfTemplateAction.type;
  }

  /**
   * Returns DisplayableServerError if it exists
   */
  public get displayableServerError(): DisplayableServerError | null {
    return this.state.displayableServerError;
  }

  /**
   * Show the previous step and hide error if it exists
   */
  public previousStep(): void {
    this.drawerStatusService.reset();
    this.state.showPreviousStep();
  }

  /**
   * Sets type of template and opens drawer
   *
   * @param templateAction type of template action
   */
  public openDrawer(templateAction: TemplateAction): void {
    this.state.setTypeOfTemplateAction(templateAction);
    this.drawer.open();
  }

  /**
   * Close the drawer
   */
  public closeDrawer(): void {
    if (this.state.hasFormValueChanged()) {
      this.openUnsavedChangesDialog();
    } else {
      this.drawer.close();
      this.reset();
    }
  }

  public openUnsavedChangesDialog(): void {
    this.unsavedChangesDialogService.open().pipe(take(1)).subscribe((choseToLeave) => {
      if (choseToLeave) {
        this.drawer.close();
        this.reset();
      }
    });
  }

  /**
   * Reset drawer to the original state
   */
  public reset(): void {
    this.drawerStatusService.reset();
    this.state.resetForm();
  }

  /**
   * Returns whether to enable the next button at any given time. Used across all steps!
   * If the loading state is active disable buttons
   */
  public enableNextButton(): boolean {
    if (this.status === LOADING) {
      return false;
    }
    return this.state.currentStepIsValid() || this.enableNextBtnManually;
  }

  /**
   * Scroll to top of form when step changes
   */
  public scrollToTop(): void {
    if (this.dialogContent) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { isMoment, Moment, utc } from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { ClientInfo, Exam, GetPoolNameResponse, PoolDetails, Queue } from 'api/types';
import { convertToPayload, convertToPoolRelease } from 'components/common/pools/pool-release/utils/pool-release.utils';
import { poolReleaseValidator } from 'components/common/pools/pool-release/validator/pool-release.validator';
import { EXCHANGE_FORMAT } from 'constants/date-formats';
import { dayNumber, daysOfTheWeek } from 'constants/days-of-the-week';
import { DatePickerErrorMatcher } from 'utils/error-matchers/date-picker.error-matcher';
import { chronologicalOrderValidator } from 'utils/validators/chronological-order.validator';
import { pastDateValidator } from 'utils/validators/past-date.validator';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { PoolsService } from 'services/api/pools.service';
import { PoolNameErrorHandler } from 'components/drawers/add-pool-drawer/pool-name-error-handler/pool-name-error-handler.class';
import { TranslatePipe, TranslationKey } from 'pipes/translate.pipe';
import { invalidCharactersValidator, INVALID_CHARACTERS_ERROR } from 'utils/validators/invalid-characters.validator';
import { ClientsService } from 'services/api/clients.service';
import { ExamsService } from 'services/api/exams.service';
import { SuppressErrorMatcher } from 'utils/error-matchers/suppress.error-matcher';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { EditPoolService } from 'services/edit-pool.service';

/**
 *  Edit pool form
 */
@Component({
  selector: 'app-edit-pool-form',
  templateUrl: './edit-pool-form.component.html',
  styleUrls: [ './edit-pool-form.component.scss' ]
})
export class EditPoolFormComponent implements AfterViewInit, OnDestroy, OnChanges {
  /**
   * Full pool details to fill out form
   */
  @Input() public pool!: PoolDetails;

  @Input() public initialpoolDetails!: PoolDetails;
  /**
   * Alert drawer when form validity changes
   * Used primarily to disable next button
   */
  @Output() public formValidityChange = new EventEmitter<boolean>();

  /**
   * Emits event of updated pool details
   */
  @Output() public formSubmitted = new EventEmitter<PoolDetails>();

  /**
   * FormGroup for the edit pool form
   */
  public editPoolForm: UntypedFormGroup;

  /**
   * Represents selected occurrence days by their number
   * Ex: sunday -> 0, monday -> 1, etc.
   */
  public selectedDayNumbers: number[] = [];

  /**
   * Min date for editing a pool
   * If pool start date is a future date it is the minimum date
   * If pool start date is past date, today is minimum date
   */
  public minDate: Moment = utc();

  /**
   * Error matcher for datepicker components
   */
  public datePickerErrorMatcher = new DatePickerErrorMatcher();

  /**
   * Terminate all subscriptions on destroy
   */
  private destroyed$ = new Subject();

  /**
 * Custom error matcher for required form fields
 * Never show error state
 * Submitting form is disabled until fields are valid
 */
  public suppressErrorState = new SuppressErrorMatcher();

  /**
   * Pool release validator that is applied at the form level.
   *
   * If control names for `poolRelease` & `endDate` change they should be updated here as well.
   */
  private poolReleaseValidator = poolReleaseValidator('poolRelease', 'endDate');

  public poolNameErrorHandler = new PoolNameErrorHandler();

  /**
   * Initial values of the editPoolForm, used to reset the form
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initialValues: {[key: string]: any};

  public translations: {[key: string]: string} = {};

  public constructor(private poolService: PoolsService,
    private drawerStatusService: DrawerStatusService,
    private getClientsService: ClientsService,
    private getExamsService: ExamsService,
    private editPoolService: EditPoolService,
    private translatePipe: TranslatePipe) {
    // Create form
    this.editPoolForm = this.createEditPoolForm();

    this.initialValues = this.editPoolForm.getRawValue();

    this.translatePipe.loadTranslations([ 'error.message.invalidclientexammapping','error.title.invalidclientexammapping'])
    .pipe(take(1))
    .subscribe((translations) => {
      this.translations = translations;
    });
  }

  /**
   * When pool details change, update the edit pool form
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('pool' in changes) {
      /*
       * Reset form if pool is falsy or an empty object.
       * This can occur when closing the edit pool dialog and the underlying data is cleared.
       */
      if (!changes.pool.currentValue || Object.keys(changes.pool.currentValue).length === 0) {
        this.editPoolForm.reset(this.initialValues);
        return;
      }

      this.setPoolDetails(changes.pool.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    const { daysOfWeek, poolRelease, startDate, examIds, clientIds } = this.editPoolForm.controls;

    // Enable/Disable exam ids based on if a client is selected
    clientIds.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((selectedIds) => {
      if (selectedIds.length) {
        examIds.enable();
        
        selectedIds.forEach((client: ClientInfo) => {
          examIds.value?.forEach((exam: Exam) => {
            if (exam.isValid && selectedIds.findIndex((i: any) => i.id === exam.clientId) < 0) {
              exam.isValid = false;
            }
            if (exam.clientId == client.id) {
              exam.isValid = true; 
            }
          });
        });
      } else {
        examIds.value?.forEach((exam: Exam) => {
          exam.isValid = false; 
        });
      }
    });

    // Emit form validity
    this.editPoolForm.statusChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((status) => {
        this.formValidityChange.emit(status === 'VALID');
      });

    // Store selected days by their day number
    daysOfWeek.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((selectedDays) => {
      this.selectedDayNumbers = selectedDays.map((day: string) => {
        return dayNumber[ day ];
      });
    });

    // Set pool release validators based on its own value
    poolRelease.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((release) => {
      if (release === null) {
        // remove pool release validator if a pool isn't auto-released
        this.editPoolForm.removeValidators(this.poolReleaseValidator);
        this.editPoolForm.updateValueAndValidity({ emitEvent: false });
      } else if (!this.editPoolForm.hasValidator(this.poolReleaseValidator)) {
        // add pool release validator if a pool is auto released
        this.editPoolForm.addValidators(this.poolReleaseValidator);
        this.editPoolForm.updateValueAndValidity({ emitEvent: false });
      }
    });

    // Set minimum date of the end date field when start date changes
    startDate.valueChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe(() => {
        this.setMinimumDate();
      });

    // Trigger value change for side effects of initial values
    clientIds.updateValueAndValidity();
    daysOfWeek.updateValueAndValidity();
  }

  /**
   * Get name from respective object
   *
   * @param obj Exam | ClientInfo
   * @returns object name property
   */
  public getClientName(obj: ClientInfo): string {
    return obj.name;
  }

  public getExamName(obj: Exam): string {
    return obj.clientCode + ': ' + obj.name;
  }

  public getClientIsValidFlag(obj: ClientInfo): boolean {
    return true;
  }

  public getExamIsValidFlag(obj: Exam): boolean {
    if(obj.isValid == undefined) {
      return obj.isValid = true;
    } 
    return obj.isValid;
  }
  
  /**
   * Get id from respective object
   *
   * @param obj Individual exam
   * @returns object id property
   */
  public getId(obj: Exam | Queue | ClientInfo): string {
    return obj.id;
  }

  /**
   * Passed to autocomplete to retrieve list of exams
   *
   * @returns Observable to getExams call
   */
  public getExams(startsWith?: string): Observable<Exam[]> {
    const { clientIds } = this.editPoolForm.controls;
    return this.getExamsService.getExams({
      startsWith,
      clientIds: clientIds.value.map((client: ClientInfo) => client.id)
    });
  }

  /**
   * Passed to autocomplete to retrieve list of clients
   *
   * @param searchTerm optional search term from autocomplete
   * @returns Observable to getClients call
   */
  public getClients(searchTerm = ''): Observable<ClientInfo[]> {
    return this.getClientsService.getClientsMst({ searchTerm }).pipe(map((value) => {
      return value;
    }));
  }

  /**
   * Complete all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Based on pool details set the values of the edit pool form
   */
  public setPoolDetails(pool: PoolDetails): void {
    const { name, startDate, endDate, daysOfWeek, autorelease, isRestricted, dateExceptions, dateAdditions, daysOfWeekWithRestriction, clients, exams } = pool;

    this.editPoolForm.setValue({
      poolName: name,
      daysOfWeek,
      daysOfWeekWithRestriction,
      dateExceptions: (dateExceptions || []),
      dateAdditions: (dateAdditions || []),
      startDate: utc(startDate),
      endDate: utc(endDate),
      poolRelease: autorelease ? convertToPoolRelease(autorelease) : null,
      isRestricted,
      clientIds: (clients || []),
      examIds: (exams || []),
    });

    this.editPoolForm.updateValueAndValidity();

    this.setMinimumDate();
    this.disableDatesInPast();
  }

  /**
   * Sets minimum date to be used by form components
   */
  public setMinimumDate(): void {
    const startDate = this.editPoolForm.controls.startDate.value;
    const today = utc();
    if (isMoment(startDate) && startDate?.isSameOrAfter(today)) {
      this.minDate = startDate;
    } else {
      this.minDate = today;
    }
  }

  /**
   * Disables dates that haven't been selected in "occurs on" field
   * Opposite of filterDatesForExceptions
   *
   * @param date from date picker
   * @returns true if date should be selectable
   */
  public filterDatesForExceptions(date: Moment): boolean {
    return this.selectedDayNumbers.includes(date.day());
  }

  /**
   * Enables dates that haven't been selected in "occurs on" field
   * Opposite of filterDatesForExceptions
   *
   * @param date from date picker
   * @returns true if date should be selectable
   */
  public filterDatesForAdditions(date: Moment): boolean {
    return !this.selectedDayNumbers.includes(date.day());
  }

  /**
   * Emits updated pool details value from edit form
   */
  public emitUpdatedPool(): void {
    const {
      poolName,
      startDate,
      endDate,
      dateAdditions,
      dateExceptions,
      poolRelease,
      isRestricted,
      daysOfWeek,
      daysOfWeekWithRestriction,
      clientIds,
      examIds
    } = this.editPoolForm.getRawValue();

    const release = convertToPayload(poolRelease);
    // eslint-disable-next-line no-undefined
    this.pool.autorelease = release ? release : undefined;
    const updatedPool: PoolDetails = {
      ...this.pool,
      name: poolName,
      daysOfWeek,
      daysOfWeekWithRestriction: this.getDaysOfWeekWithRestriction(daysOfWeek, daysOfWeekWithRestriction),
      isRestricted,
      startDate: startDate.format(EXCHANGE_FORMAT),
      endDate: endDate.format(EXCHANGE_FORMAT),
      dateAdditions: (dateAdditions || []),
      dateExceptions: (dateExceptions || []),
      clients: (clientIds || []),
      exams: (examIds || [])
    };
    this.formSubmitted.emit(updatedPool);
  }

  public getDaysOfWeekWithRestriction(daysOfWeek: any, daysOfWeekWithRestriction: any[]) {
    if (daysOfWeekWithRestriction?.length) {
      daysOfWeekWithRestriction = daysOfWeekWithRestriction.map((day) => {
        return {
          ...day,
          checked: daysOfWeek?.length && daysOfWeek?.find((i: any) => i === day.value) ? day.checked : false
        };
      });

      return daysOfWeekWithRestriction;
    }
    else {
      return this.pushDefaultDataInJson(daysOfWeek)
    }
  }

  public pushDefaultDataInJson(daysOfWeek : any) {
    return daysOfTheWeek.map((day, i) => {
     return {
       value: day,
       checked: daysOfWeek?.length && daysOfWeek?.find((i: any) => i === day) ? true : false
     };
   });
 }

  public validatePoolName(): void {
    // Mark form as pristine, so we can check user interaction in error state
    this.editPoolForm.markAsPristine();
    const { poolName, clientIds, examIds } = this.editPoolForm.value;

    if (poolName.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase() != this.initialpoolDetails.name.toLowerCase()) {
      this.drawerStatusService.loading();

      this.poolService.getPoolNames({ startsWith: poolName.toString().replace(/^\s+/g, '').replace(/\s+$/g, '') })
        .pipe(take(1))
        .subscribe((pools) => {
          this.checkPoolNamesForEdit(pools);
        }, () => {
          this.drawerStatusService.error();
        });
    }
    else {
      var isValidClientExamMapping = true;
      examIds?.forEach((exam: Exam) => {
        if (clientIds.findIndex((i: any) => i.id === exam.clientId) < 0) {
          isValidClientExamMapping = false;
        }
      });

      if (isValidClientExamMapping) {
        this.editPoolService.displayableServerError = null;
        this.emitUpdatedPool();
      }
      else {
        var errorObj: DisplayableServerError = { title: this.translations['error.title.invalidclientexammapping'], message: this.translations['error.message.invalidclientexammapping'] };
        this.editPoolService.displayableServerError = errorObj;
        this.drawerStatusService.error();
      }
    }
  }

  private checkPoolNamesForEdit(pools: GetPoolNameResponse): void {
    const { poolName, clientIds, examIds } = this.editPoolForm.controls;
    const name = poolName.value;

    // Check for duplicate name
    const duplicateName = Boolean(pools.find((p) => p.name.toLowerCase() === name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase()));

    // Update error state
    this.poolNameErrorMatcher.setDuplicateNameError(duplicateName);
    poolName.updateValueAndValidity();

    if (!duplicateName) {
      var isValidClientExamMapping = true;
      examIds.value?.forEach((exam: Exam) => {
        if (clientIds.value?.findIndex((i: any) => i.id === exam.clientId) < 0) {
          isValidClientExamMapping = false;
        }
      });

      if (isValidClientExamMapping) {
        this.editPoolService.displayableServerError = null;
        this.emitUpdatedPool();
      }
      else {
        var errorObj : DisplayableServerError = { title: this.translations['error.title.invalidclientexammapping'], message: this.translations['error.message.invalidclientexammapping'] };
        this.editPoolService.displayableServerError = errorObj;
        this.drawerStatusService.error();
      }
    } else {
      this.drawerStatusService.reset();
    }
  }

  public get poolNameErrorMatcher(): PoolNameErrorHandler {
    return this.poolNameErrorHandler;
  }

  public poolNameErrorMessageKey(): TranslationKey {
    if (this.editPoolForm.controls.poolName.hasError(INVALID_CHARACTERS_ERROR)) {
      return 'error.invalidCharacter';
    }
    return 'error.message.poolNameAlreadyExists';
  }

  /**
   * Set empty values for form group
   *
   * @returns empty form group
   */
  private createEditPoolForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      poolName: new UntypedFormControl('', {
        validators: [
          Validators.required,
          invalidCharactersValidator,
          this.poolNameErrorMatcher.duplicateNameValidator.bind(this.poolNameErrorMatcher)
        ]
      }),
      clientIds: new UntypedFormControl([], {
        validators: [
          Validators.required,
          Validators.minLength(1)
        ]
      }),
      examIds: new UntypedFormControl([]),
      startDate: new UntypedFormControl('', { validators: [Validators.required, pastDateValidator], updateOn: 'blur' }),
      endDate: new UntypedFormControl('', { validators: [Validators.required, pastDateValidator], updateOn: 'blur' }),
      daysOfWeek: new UntypedFormControl([], {
        validators: [
          Validators.required,
          Validators.minLength(1)
        ]
      }),
      daysOfWeekWithRestriction: new UntypedFormControl([]),
      dateExceptions: new UntypedFormControl([]),
      dateAdditions: new UntypedFormControl([]),
      poolRelease: new UntypedFormControl(null),
      isRestricted: new UntypedFormControl(true, Validators.required)
    }, {
      validators: [ chronologicalOrderValidator('startDate', 'endDate') ],
    });
  }

  /**
   * Disables start & end date fields if they're in past
   */
  private disableDatesInPast(): void {
    const { startDate, endDate } = this.editPoolForm.controls;
    const today = utc();

    // Start date
    if (utc(this.pool.startDate).isBefore(today, 'day') && startDate.enabled) {
      startDate.disable({ emitEvent: false });
    } else if (startDate.disabled) {
      startDate.enable();
    }

    // End date
    if (utc(this.pool.endDate).isBefore(today, 'day') && endDate.enabled) {
      endDate.disable({ emitEvent: false });
    } else if (endDate.disabled) {
      endDate.enable();
    }
  }
}

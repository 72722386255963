import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { utc } from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CapacityTemplateDetails } from 'api/types';
import { CreateCapacityTemplateDay, CreateCapacityTemplateRequestBody, CreateCapacityTemplateResponse, UpdateCapacityTemplateResponse } from 'api/types';
import { Day } from 'constants/days-of-the-week';
import { CapacityTemplatesService } from 'services/api/capacity-templates.service';
import { DrawerStatusService } from 'services/status/drawer-status.service';
import { DisplayableServerError } from 'types/DisplayableServerError';
import { getDisplayableServerError } from 'utils/get-displayable-server-error';
import { invalidCharactersValidator } from 'utils/validators/invalid-characters.validator';
import { percentAllocationValidator } from '../percent-allocation-validator/percent-allocation.validator';
import { TemplateNameErrorHandler } from '../template-name-error-handler/template-name-error-handler.class';

export const TemplateSteps = [
  'form', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
] as const;
export type TemplateStep = typeof TemplateSteps[number];

export type TemplateAction =
  {type: 'edit', id: string, status: CapacityTemplateDetails['status'] } |
  {type: 'new'} |
  {type: 'duplicate'} |
  {type: 'active', id: string, status: CapacityTemplateDetails['status'] }

/**
 *  Manage shared state for the add template flow
 */
@Injectable({
  providedIn: 'root'
})
export class AddTemplateState {
  /**
   * The overarching formGroup for the entire flow
   */
  public formGroup: UntypedFormGroup;

  /**
   * Current step in the drawer screen flow
   */
  public step$ = new BehaviorSubject<TemplateStep>('form');

  /**
   * Template submission success
   */
  public templateSuccess$ = new Subject<{templateName: string, type: 'new' | 'edit' | 'active'}>();

  /**
   * Type of template action
   * AddTemplateDrawer can be used for new templates, editing existing or duplicating
   */
  public typeOfTemplateAction: TemplateAction = { type: 'new' };

  /**
   * DisplayableServerError from template service
   */
  public displayableServerError: DisplayableServerError | null = null;

  /**
   * Initial values of form fields
   */
  private initialValues: {[key: string]: unknown} = {};

  public initialNameControlValue: string = "";

  public templateNameErrorHandler = new TemplateNameErrorHandler();

  public enableNextBtnManually$ = new Subject<boolean>();

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private capacityTemplateService: CapacityTemplatesService,
    private drawerStatusService: DrawerStatusService,
  ) {
    this.formGroup = this.createMainFormGroup();

    // Save initial values for reset
    this.initialValues = this.formGroup.value;
  }

  /**
   * Create percent field form control with appropriate validators
   *
   * @returns control
   */
  private static percentControl(): UntypedFormControl {
    return new UntypedFormControl('', {
      validators: [
        Validators.min(0),
        Validators.max(100),
      ]
    });
  }

  /**
   * Show the next step in the flow
   */
  public showNextStep(): void {
    const nextIndex = TemplateSteps.indexOf(this.step$.value) + 1;
    if (nextIndex < TemplateSteps.length) {
      this.showStep(TemplateSteps[ nextIndex ]);
    }
  }

  /**
   * Show the previous step in the flow
   */
  public showPreviousStep(): void {
    const previousIndex = TemplateSteps.indexOf(this.step$.value) - 1;
    if (previousIndex !== -1) {
      this.showStep(TemplateSteps[ previousIndex ]);
    }
  }

  /**
   * Check if visible form is valid based in step value
   *
   * @returns true if current form is valid
   */
  public currentStepIsValid(): boolean {
    switch (this.step$.value) {
      case 'form':
        return this.firstStep.status === 'VALID';
      default:
        return this.hourlyAllocations(this.step$.value).status === 'VALID';
    }
  }

  /**
   * Get the total used percentage for weekly form
   *
   * @returns total the percent used so far
   */
  public weeklyAllocationsTotalUsed(): number {
    if (!this.formGroup?.controls) {
      return 0;
    }
    return this.sumPercentControls(this.weeklyAllocations.controls);
  }

  /**
   * Copy one day allocations to another days allocations
   *
   * @param originDay day that is being copied
   * @param destinationDay day that is getting copied to
   */
  public copyAllocation(originDay: Day, destinationDay: Day): void {
    this.hourlyAllocations(destinationDay).setValue(
      this.hourlyAllocations(originDay).value
    );
  }

  /**
   * Getter for first step FormGroup
   *
   * @returns first step FormGroup
   */
  public get firstStep(): UntypedFormGroup {
    return this.formGroup.controls.firstStep as UntypedFormGroup;
  }

  /**
   * Getter for directly access name FormControl
   *
   * @returns first step Name FormControl
   */
  public get nameControl(): UntypedFormControl {
    return this.firstStep.controls.name as UntypedFormControl;
  }

  /**
   * Getter for directly access weekly allocations FormArray
   *
   * @returns weekly allocations FormArray
   */
  public get weeklyAllocations(): UntypedFormArray {
    return this.firstStep.controls.weeklyAllocations as UntypedFormArray;
  }

  /**
   * Retrieve FormArray for a specific week day
   *
   * @param day day to retrieve
   * @returns FormArray
   */
  public hourlyAllocations(day: Day): UntypedFormArray {
    return this.formGroup.controls[ day ] as UntypedFormArray;
  }

  /**
   * Get total used for a given day
   *
   * @param day the day of the week to get totals for
   * @returns total the percent used so far
   */
  public dailyTotalUsed(day: Day): number {
    return this.sumPercentControls(this.hourlyAllocations(day).controls);
  }

  /**
   * Sets type of template action
   *
   * @param type type of template action
   */
  public setTypeOfTemplateAction(type: TemplateAction): void {
    this.typeOfTemplateAction = type;
  }

  /**
   * Pre-fill form values with all template information
   *
   * @param template full template details
   */
  public updateFormWithEditValues(template: CapacityTemplateDetails): void {
    this.preFillFormWithTemplateData(template);
  }

  /**
   * Submit template action
   */
  public submitTemplate(): void {
    this.drawerStatusService.loading();
    const payload = this.constructSubmissionPayload();
    let templateActionService: Observable<CreateCapacityTemplateResponse | UpdateCapacityTemplateResponse>;

    if (this.typeOfTemplateAction.type === 'edit' || this.typeOfTemplateAction.type === 'active') {
      // Update an existing template
      templateActionService = this.capacityTemplateService.updateCapacityTemplate(
        this.typeOfTemplateAction.id,
        {
          ...payload,
          status: this.typeOfTemplateAction.status
        }
      );
    } else {
      // Create a new template, same logic for brand new template & duplicating an old one
      templateActionService = this.capacityTemplateService.createCapacityTemplate(payload);
    }

    templateActionService
      .pipe(take(1))
      .subscribe(() => {
        this.drawerStatusService.success();
        let type: 'new' | 'edit' | 'active' = 'edit';
        if (this.typeOfTemplateAction.type === 'new') {
          type = 'new';
        }
        else if (this.typeOfTemplateAction.type === 'active') {
          type = 'active';
        }
        else {
          type = 'edit';
        }

        this.templateSuccess$.next({
          templateName: payload.name,
          type: type
        });
        this.resetForm();
      }, (error: unknown) => {
        this.displayableServerError = getDisplayableServerError(error);
        this.drawerStatusService.error();
      });
  }

  /**
   * Resets form back to initial values
   */
  public resetForm(): void {
    this.displayableServerError = null;
    this.showStep('form');
    this.formGroup.reset(this.initialValues);
    this.enableNextBtnManually$.next(false);
  }

  public hasFormValueChanged(): boolean {
    return JSON.stringify(this.formGroup.getRawValue()) !== JSON.stringify(this.initialValues);
  }

  /**
   * Show the given step in the flow
   *
   * @param step the step to show
   */
  private showStep(step: TemplateStep): void {
    this.step$.next(step);
  }

  /**
   * Create the super-form that contains all of the data for the whole flow
   *
   * @returns FormGroup
   */
  private createMainFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      firstStep: this.formBuilder.group({
        name: this.formBuilder.control('', {
          validators: [
            Validators.required,
            invalidCharactersValidator,
            this.templateNameErrorHandler.duplicateNameValidator.bind(this.templateNameErrorHandler)
          ]
        }),

        // High-level allocations for each day in the week
        weeklyAllocations: this.formBuilder.array(
          new Array(7).fill(null).map(AddTemplateState.percentControl),
          { validators: [ percentAllocationValidator ] }
        ),
      }),
      sunday: this.createDailyAllocationsFormArray(),
      monday: this.createDailyAllocationsFormArray(),
      tuesday: this.createDailyAllocationsFormArray(),
      wednesday: this.createDailyAllocationsFormArray(),
      thursday: this.createDailyAllocationsFormArray(),
      friday: this.createDailyAllocationsFormArray(),
      saturday: this.createDailyAllocationsFormArray(),
    });
  }

  /**
   * Generate a formArray of hourly allocation controls for one day
   *
   * @returns formArray
   */
  private createDailyAllocationsFormArray(): UntypedFormArray {
    // Create controls for each hour of the day
    const controls = new Array(24).fill(null).map(AddTemplateState.percentControl);
    return this.formBuilder.array(controls, [ percentAllocationValidator ]);
  }

  /**
   * Sums up the values of an array of percent controls
   *
   * @param controls the array of controls
   * @returns sum
   */
  private sumPercentControls(controls: AbstractControl[]): number {
    const sum = controls.reduce((total, control) => {
      return total + parseFloat(control.value || 0);
    }, 0);

    /*
     * Round decimals to hundredths, avoid JavaScript math issues
     * Ex: 0.3 + 0.6 = 0.8999999999999999
     */
    return Number(sum.toFixed(2));
  }

  /**
   * Take template data structure and pre-fill form with all available data
   * Basically the opposite of constructSubmissionPayload
   *
   * @param template CapacityTemplateDetails
   */
  private preFillFormWithTemplateData(template: CapacityTemplateDetails): void {
    this.initialNameControlValue = template.name;
    this.enableNextBtnManually$.next(true);

    this.formGroup.setValue({
      firstStep: {
        name: template.name,
        weeklyAllocations: template.dailyAllocation.map((allocation) => (allocation.percent || 0).toFixed(2)),
      },
      sunday: this.getHourlyPercentagesTemplate('sunday', template.dailyAllocation),
      monday: this.getHourlyPercentagesTemplate('monday', template.dailyAllocation),
      tuesday: this.getHourlyPercentagesTemplate('tuesday', template.dailyAllocation),
      wednesday: this.getHourlyPercentagesTemplate('wednesday', template.dailyAllocation),
      thursday: this.getHourlyPercentagesTemplate('thursday', template.dailyAllocation),
      friday: this.getHourlyPercentagesTemplate('friday', template.dailyAllocation),
      saturday: this.getHourlyPercentagesTemplate('saturday', template.dailyAllocation),
    });
  }

  /**
   * @param day day of the week to get
   * @param allocations all allocations for the week
   * @returns string array of 24 values representing hourly allocations
   */
  private getHourlyPercentagesTemplate(day: Day, allocations: CapacityTemplateDetails['dailyAllocation']): string[] {
    const hourlyAllocations: string[] = new Array(24).fill('');

    const dailyAllocation = allocations.find((allocation) => allocation.dayOfWeek === day);
    if (dailyAllocation) {
      dailyAllocation.hourlyAllocation.forEach((hourlyAllocation, i) => {
        hourlyAllocations[ i ] = (hourlyAllocation.percent || 0).toFixed(2);
      });
    }

    return hourlyAllocations;
  }

  /**
   * Construct full payload for submitting a new template
   *
   * @returns CreateCapacityTemplateRequestBody
   */
  private constructSubmissionPayload(): CreateCapacityTemplateRequestBody {
    const { firstStep, sunday, monday, tuesday, wednesday, thursday, friday, saturday } = this.formGroup.value;

    return {
      name: firstStep.name,
      status: 'active',
      dailyAllocation: [
        this.getDailyAllocationPayload('sunday', firstStep.weeklyAllocations[ 0 ], sunday),
        this.getDailyAllocationPayload('monday', firstStep.weeklyAllocations[ 1 ], monday),
        this.getDailyAllocationPayload('tuesday', firstStep.weeklyAllocations[ 2 ], tuesday),
        this.getDailyAllocationPayload('wednesday', firstStep.weeklyAllocations[ 3 ], wednesday),
        this.getDailyAllocationPayload('thursday', firstStep.weeklyAllocations[ 4 ], thursday),
        this.getDailyAllocationPayload('friday', firstStep.weeklyAllocations[ 5 ], friday),
        this.getDailyAllocationPayload('saturday', firstStep.weeklyAllocations[ 6 ], saturday),
      ]
    };
  }

  /**
   * Gets payload object for an individual day with allocation information
   *
   * @param day day of the week
   * @param weeklyAllocation weekly allocation from first step of form
   * @param hourlyAllocations hourly allocations for the respective day
   * @returns CreateCapacityTemplateDay
   */
  private getDailyAllocationPayload(
    day: CreateCapacityTemplateDay['dayOfWeek'],
    weeklyAllocation: string,
    hourlyAllocations: string[]
  ): CreateCapacityTemplateDay {
    return {
      dayOfWeek: day,
      percent: this.parseAllocation(weeklyAllocation),
      hourlyAllocation: hourlyAllocations.map((allocation, i) => {
        return {
          startTime: this.getHourField(i),
          percent: this.parseAllocation(allocation)
        };
      })
    };
  }

  /**
   * Converts allocation to a number
   *
   * @param allocationString allocation in string format
   * @returns allocation as a number
   */
  private parseAllocation(allocationString: string): number {
    const allocation = parseFloat(allocationString || '0');
    return isNaN(allocation) ? 0 : allocation;
  }

  /**
   * @param hourNumber the hour number
   * @returns label as a formatted label
   */
  private getHourField(hourNumber: number): string {
    return utc().hour(hourNumber).format('HH:00');
  }
}

import { PoolStatus } from 'api/types';

/**
 * Determines if a pool/occurrence is editable or not based on the status
 *
 * @param pool Pool or Occurrence to check
 * @returns true if a pool/occurrence does not have the status of archived or complete
 */
export function isPoolEditable<P extends {status: PoolStatus}>(pool: P): boolean {
  const { status } = pool;
  return ![ 'archived', 'completed' ].includes(status);
}

<form id="add-template-form" [formGroup]="formGroup" (ngSubmit)="validateTemplateName()" autocomplete="off">
  <vue-text-input
    label="{{ 'label.placeholder.templateName' | translate | async }}"
    errorMessage="{{ templateNameErrorMessageKey() | translate | async }}"
    [formControl]="nameControl"
    [required]="true"
    [errorMatcher]="templateNameErrorMatcher"
  ></vue-text-input>
  <h4>{{ 'title.dailyAllocation' | translate | async }}</h4>
  <div class="numeric-fields">
    <div
      *ngFor="let control of weeklyAllocationControls, let i = index"
      class="percent-field-row"
    >
      <label [for]="'day_' + i">
        {{getDayFieldLabel(i)}}
      </label>
      <vue-percent-input
        [id]="'day_' + i"
        [formControl]="control"
      ></vue-percent-input>
    </div>
    <div class="percent-field-row">
      <label>{{ 'label.weekTotal' | translate | async }}</label>
      <app-percent-total [total]="totalUsed"></app-percent-total>
    </div>
  </div>
</form>

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Optional, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DateFilterFn, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Moment, utc } from 'moment';
import { noop } from 'rxjs';

/**
 *  Vue Datepicker
 */
@Component({
  selector: 'vue-datepicker',
  templateUrl: './vue-datepicker.component.html',
  styleUrls: [ './vue-datepicker.component.scss' ],
})
export class VueDatePickerComponent implements ControlValueAccessor {
  /**
   * Label shown on date picker input
   */
  @Input() public label = '';

  /**
   * Placeholder shown when label floats to the top of the input
   */
  @Input() public placeholder = '';

  /**
   * If date value is required
   */
  @Input() public required = false;

  /**
   * Currently selected date passed to date picker component
   */
  @Input() public selectedDate: Moment | null = null;

  /**
   * Minimum date selectable threshold
   * All dates before will be disabled
   */
  @Input() public minDate: Moment | null = null;

  /**
   * Maximum date selectable threshold
   * All dates after will be disabled
   */
  @Input() public maxDate: Moment | null = null;

  /**
   * Set today in UTC time as minimum date
   * If minDate is defined it will take precedence
   */
  @Input() public minDateIsToday?: boolean;

  /**
   * MatFormFieldAppearance string
   *
   * @default outline
   */
  @Input() public appearance: MatFormFieldAppearance = 'outline';

  /**
   * Custom error matcher for date picker form field
   */
  @Input() public errorMatcher = new ErrorStateMatcher();

  /**
   * Only enable certain dates of the week based on their index
   *
   * @example [0] = Sunday, [6] = Saturday
   * @default [0,1,2,3,4,5,6] all days enabled
   */
  @Input() public filterDaysOfWeek: number[] = [ 0, 1, 2, 3, 4, 5, 6 ];

  /**
   * Toggle disabled state of datepicker
   */
  @Input() public disable = false;

  /**
   * Error message for the invalid state of the date picker
   */
  @Input() public errorMessage?: string | null;

  /**
   * Output event of the date as a moment or null if empty
   */
  @Output() public dateChanged = new EventEmitter<Moment | null>()

  // Can't bind the daysFilter function in the html because it breaks the ability to click a date
  // eslint-disable-next-line no-invalid-this
  public boundDateFilter: DateFilterFn<Moment | null> = this.daysFilter.bind(this);

  // ControlValueAccessor functions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback = noop;

  private defaultFormControl = new UntypedFormControl();

  public constructor(@Optional() private ngControl: NgControl) {
    // Set ControlValueAccessor of the control to the datepicker instance
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Update control value based on given selected date
   * Important for when datepicker is used without an input control
   */
  public ngOnInit(): void {
    if (this.selectedDate) {
      this.control.setValue(this.selectedDate);
    }
  }

  /**
   * Update control value/state when selectedDate or disabled changes
   * Important for when datepicker is used without an input control
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('selectedDate' in changes) {
      this.control.setValue(changes.selectedDate.currentValue);
    }

    if ('disable' in changes) {
      this.setDisabledState(Boolean(changes.disable.currentValue));
    }
  }

  /**
   * Retrieve the control either passed by a parent form,
   * or use the default control if the datepicker is being consumed outside of a form
   */
  public get control(): UntypedFormControl {
    return this.ngControl?.control ? this.ngControl.control as UntypedFormControl : this.defaultFormControl;
  }

  /**
   * Filters certain days of the week out if parent passes in specific filterDaysOfWeek array
   *
   * @param d the date currently being evaluated
   * @returns true if the date is in the specified days of week and false if not
   */
  public daysFilter(d: Moment | null): boolean {
    return Boolean(d && this.filterDaysOfWeek.includes(d.day()));
  }

  /**
   * Chooses Minimum date based on inputs
   *
   * @returns min date of datepicker
   */
  public getMinDate(): Moment | null {
    if (
      this.minDate &&
      (!this.minDateIsToday || (this.minDateIsToday && this.minDate.isSameOrAfter(utc(), 'day')))
    ) {
      return this.minDate;
    } else if (this.minDateIsToday) {
      return utc();
    }
    return null;
  }

  /**
   * Handle change event for form and observable
   *
   * @param dateChange Change event from MatDatePicker
   */
  public changed(dateChange: MatDatepickerInputEvent<Moment>): void {
    this.onChangeCallback(dateChange.value);
    // eslint-disable-next-line no-undefined
    this.dateChanged.emit(dateChange.value);
    this.onTouchedCallback();
  }

  /**
   * ControlValueAccessor method
   * Accepts value from parent form
   *
   * @param date incoming date value
   */
  public writeValue(date: Moment): void {
    this.selectedDate = date;
  }

  /**
   * ControlValueAccessor method
   * Sets onChangeCallback that will update the parent form value when date value is updated
   *
   * @param fn function to call when date is updated
   */
  public registerOnChange(fn: (_: any) => void): void {
    this.onChangeCallback = fn;
  }

  /**
   * ControlValueAccessor method
   * Sets onTouchedCallback that will update the parent form that the control now has a touched state
   *
   * @param fn function to call when input is touched
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  /**
   * Set local disabled state based on the control
   */
  public setDisabledState(isDisabled: boolean): void {
    // eslint-disable-next-line no-unused-expressions
    if (isDisabled && !this.control.disabled) {
      this.control.disable();
    }
    else if (!isDisabled && this.control.disabled) {
      this.control.enable();
    }
    //isDisabled ? this.control.disable() : this.control.enable();
  }
}

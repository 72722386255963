<form id="add-template-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="form-title">
    <h4 aria-live="polite">{{dayName}} {{ 'title.hourlyAllocation' | translate | async}}</h4>
    <app-copy-allocations
      *ngIf="dayNumber !== 0"
      [dayNumber]="dayNumber"
    ></app-copy-allocations>
  </div>
  <div class="numeric-fields">
    <!-- First column of hours: 00:00 through 11:00 -->
    <div class="first-column">
      <div
        *ngFor="let control of firstHalfHourlyControls, let i = index"
        class="percent-field-row"
      >
        <label [for]="'hour_' + i">
          {{hourFieldLabel(i)}}
        </label>
        <vue-percent-input
          [id]="'hour_' + i"
          [formControl]="control"
        ></vue-percent-input>
      </div>
    </div>
    <!-- Second column of hours: 12:00 through 23:00 -->
    <div class="second-column">
      <div
        *ngFor="let control of secondHalfHourlyControls, let i = index"
        class="percent-field-row"
      >
        <label [for]="'hour_' + (i + 12)">
          {{hourFieldLabel(i+12)}}
        </label>
        <vue-percent-input
          [id]="'hour_' + (i + 12)"
          [formControl]="control"
        ></vue-percent-input>
      </div>
    </div>
  </div>
  <div class="day-total">
    <label>{{ dayName }} {{ 'label.total' | translate | async }}</label>
    <app-percent-total [total]="totalUsed()"></app-percent-total>
  </div>
</form>

<vue-table-container [loading]="tableLoading">
  <table mat-table [dataSource]="alertsData">
    <caption class="visually-hidden">
      {{
      'table.caption.allAlerts' | translate | async
      }}
    </caption>

    <!-- Alert Name -->
    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'priority' }">
        <app-sortable-table-header [tableHasData]="!!alertsData.length" (sortColumn)="sort('priority')">
          Priority
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let element">
        {{ element.priority }}
        <div class="dot" [ngClass]="setColorCode(element.priority)"></div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'type' }">
        <app-sortable-table-header [tableHasData]="!!alertsData.length" (sortColumn)="sort('type')">
          Type
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let element">
        {{ element.type }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="queue">
      <th mat-header-cell *matHeaderCellDef>
        Queue
      </th>
      <mat-cell *matCellDef="let element" [matTooltip]="getQueueDisplay(element)">
        <span class="ellipsis">
        {{ getQueueDisplay(element) }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdon">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'createdon' }">
        <app-sortable-table-header [tableHasData]="!!alertsData.length" (sortColumn)="sort('createdon')">
          Created On
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let element">
        {{ formatDate(element.createdOn) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'details' }">
        <app-sortable-table-header [tableHasData]="!!alertsData.length" (sortColumn)="sort('details')">
          Details
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let element" [matTooltip]="element.details">
        <span class="ellipsis">
          {{ element?.details }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef [tableHeaderAriaSort]="{sortDirection, isSorted: sortBy === 'status' }">
        <app-sortable-table-header [tableHasData]="!!alertsData.length" (sortColumn)="sort('status')">
          Status
        </app-sortable-table-header>
      </th>
      <mat-cell *matCellDef="let element">
        {{ element.status }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef>View</th>
      <mat-cell style="cursor: pointer;" *matCellDef="let element">
        <mat-icon>remove_red_eye</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Populate table with data -->
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns; let i = index"></mat-row>

    <!-- No data available -->
    <tr class="mat-row no-content" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{ 'message.alerts.noAlerts' | translate | async }}
      </td>
    </tr>
  </table>

  <mat-paginator *ngIf="alertsData.length !== 0" (page)="paginatorChange($event)" previousPageIndex="0"
    [length]="totalItemCount" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"></mat-paginator>
</vue-table-container>
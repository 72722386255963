import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { utc } from 'moment';
import { FULL_DAY_FORMAT } from 'constants/date-formats';
import { AddTemplateState } from '../add-template-state/add-template-state.service';
import { GetTemplateNameResponse } from 'api/types/endpoints/getTemplateName';
import { take } from 'rxjs/operators';
import { CapacityTemplatesService } from 'services/api/capacity-templates.service';
import { TranslationKey } from 'pipes/translate.pipe';
import { INVALID_CHARACTERS_ERROR } from 'utils/validators/invalid-characters.validator';
import { TemplateNameErrorHandler } from '../template-name-error-handler/template-name-error-handler.class';
import { DrawerStatusService } from 'services/status/drawer-status.service';

/**
 * Add template form
 * Template name & weekly allocation
 */
@Component({
  selector: 'app-add-template-form',
  templateUrl: './add-template-form.component.html',
  styleUrls: [ './add-template-form.component.scss' ]
})
export class AddTemplateFormComponent {
  @Input() public type: 'new' | 'edit' | 'duplicate' | 'active' = 'new';
  /**
   * Main FormGroup for add template flow
   */
  public formGroup: UntypedFormGroup;

  public initialNameControlValue: string = "";

  /**
   * Array of form controls for weekly allocations
   */
  public weeklyAllocationControls: UntypedFormControl[];

  public nameControl: UntypedFormControl;

  public constructor(private state: AddTemplateState,
    private capacityTemplatesService: CapacityTemplatesService,
    private drawerStatusService: DrawerStatusService) {
    this.formGroup = this.state.formGroup;
    this.initialNameControlValue = this.state.initialNameControlValue;
    this.weeklyAllocationControls = this.state.weeklyAllocations.controls as UntypedFormControl[];
    this.nameControl = this.state.nameControl;
  }

  /**
   * Get the current total percentage
   *
   * @returns percent
   */
  public get totalUsed(): number {
    return this.state.weeklyAllocationsTotalUsed();
  }

  /**
   * Get the label for a specific day
   *
   * @param dayNumber the day of the week
   * @returns label
   */
  public getDayFieldLabel(dayNumber: number): string {
    return utc().day(dayNumber).format(FULL_DAY_FORMAT);
  }

  /**
   * Show next step on submission of the form
   */
  public onSubmit(): void {
    this.state.showNextStep();
  }

  public validateTemplateName(): void {
    this.state.enableNextBtnManually$.next(false);
    // Mark form as pristine, so we can check user interaction in error state
    this.nameControl.markAsPristine();
    const name = this.nameControl.value;

    if (name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase() != this.initialNameControlValue.toLowerCase() || this.type != 'edit') {
      this.drawerStatusService.loading();

      this.capacityTemplatesService.getTemplateNames({ startsWith: name.replace(/^\s+/g, '').replace(/\s+$/g, '') })
        .pipe(take(1))
        .subscribe((templates: GetTemplateNameResponse) => {
          this.checkTemplateNames(templates);
        }, () => {
          this.drawerStatusService.error();
        });
    }
    else {
      this.state.showNextStep();
    }
  }

  private checkTemplateNames(templates: GetTemplateNameResponse): void {
    const name = this.nameControl.value;

    // Check for duplicate name
    const duplicateName = Boolean(templates.find((p) => p.name.toLowerCase() === name.toString().replace(/^\s+/g, '').replace(/\s+$/g, '').toLowerCase()));

    // Update error state
    this.templateNameErrorMatcher.setDuplicateNameError(duplicateName);
    this.nameControl.updateValueAndValidity();

    if (!duplicateName) {
      this.state.showNextStep();
      this.drawerStatusService.success();
    } else {
      this.drawerStatusService.reset();
    }
  }

  public templateNameErrorMessageKey(): TranslationKey {
    if (this.nameControl.hasError(INVALID_CHARACTERS_ERROR)) {
      this.state.enableNextBtnManually$.next(false);
      return 'error.invalidCharacter';
    }
    return 'error.message.templateNameAlreadyExists';
  }

  public get templateNameErrorMatcher(): TemplateNameErrorHandler {
    return this.state.templateNameErrorHandler;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DisplayableServerError } from 'types/DisplayableServerError';

/**
 * Allows for subscription when a pool has been edited
 * Used to allow individual handling of the change instead
 * of event emitters through the component hierarchy.
 * Typically used to refresh the page data and show toast
 */
@Injectable({
  providedIn: 'root'
})

/**
 * API service for editing pools
 */
export class EditPoolService {
  private poolUpdated: Subject<{poolName: string, status: 'updated' | 'archived' | 'released'}> = new Subject();

  /**
   * An observable callers can use to subscribe to pool updates
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering, no-invalid-this
  public poolUpdated$ = this.poolUpdated.asObservable();

  public displayableServerError: DisplayableServerError | null = null;
  
  /**
   * Notify subscribers of a pool update
   *
   * @param poolName the name of the pool
   * @param status the type of change to report
   */
  public emitPoolUpdate(poolName: string, status: 'updated' | 'archived' | 'released'): void {
    this.poolUpdated.next({ poolName, status });
  }
}

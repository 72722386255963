import { Injectable } from '@angular/core';
import { GetAlertsQueryParameters, GetAlertsResponse } from 'api/types/endpoints/getAlerts';
import { PoolsMicroservice } from './microservices/pools.microservice';
import { Observable, Subject } from 'rxjs';
import { createQueryString } from 'utils/create-query-string';
import { CreateAlertPayload, CreateAlertResponse } from 'api/types/endpoints/createAlert';
import { EditAlertPayload, EditAlertResponse } from 'api/types/endpoints/editAlert';
import { GetAlertByIdQueryParameters, GetAlertByIdResponse } from 'api/types/endpoints/getAlertById';
import { DisplayableServerError } from 'types/DisplayableServerError';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  public alertsSuccess$ = new Subject<{alertId: string, type: 'new' | 'edit' | 'delete'}>();
  
  public alertsDeleteError$ = new Subject<{error: DisplayableServerError | null}>();
  
  /**
   * Default params that can be overridden by input
   */
  private defaultParams: GetAlertsQueryParameters = {
    limit: 10,
    offset: 0,
    sortBy: 'priority',
    direction: 'asc',
    status: 'all',
    type: 'all'
  };

  public constructor(private api: PoolsMicroservice) {}

  /**
   * Calls GetOutage API
   *
   * @param params optional params for get Outage call
   * @returns GetOutageResponse Observable
   */
  public getAlerts(
    params?: Partial<GetAlertsQueryParameters>
  ): Observable<GetAlertsResponse> {
    const queryString = createQueryString({
      ...this.defaultParams,
      ...params,
    });
    const path = `alerts${queryString}`;
    return this.api.get<GetAlertsResponse>(path);
  }

  public createAlerts(payload: CreateAlertPayload): Observable<CreateAlertResponse> {
    const path = 'outages';
    return this.api.post<CreateAlertResponse, CreateAlertPayload>(path, payload);
  }

  public editAlerts(id: string, payload: EditAlertPayload): Observable<EditAlertResponse> {
    const path = `outages/${id}`;
    return this.api.patch<EditAlertResponse, EditAlertPayload>(path, payload);
  }

  public getAlertsById(params: Partial<GetAlertByIdQueryParameters>): Observable<GetAlertByIdResponse> {
    const path = `alerts/${params.id}`;

    return this.api.get<GetAlertByIdResponse>(path);
  }

  public deleteAlertsById(params: Partial<GetAlertByIdQueryParameters>): Observable<GetAlertByIdResponse> {
    const path = `outages/${params.id}`;

    return this.api.delete<GetAlertByIdResponse>(path);
  }
}

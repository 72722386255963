import { Component, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslatePipe } from 'pipes/translate.pipe';
import { VueToastComponent } from 'vue/components/vue-toast/vue-toast.component';

/**
 *  Display results from an edit pool post in a toast
 */
@Component({
  selector: 'app-edit-pool-toast',
  templateUrl: './edit-pool-toast.component.html',
})
export class EditPoolToastComponent {
  @ViewChild(VueToastComponent) public toast!: VueToastComponent;

  /**
   * Text to display in toast body
   */
  public toastContent = '';

  public constructor(private translatePipe: TranslatePipe) {}

  /**
   * Open toast for successful update
   *
   * @param poolName name of pool that has been changed
   * @param updateStatus status to display in toast
   */
  public openToast(poolName: string, updateStatus: 'updated' | 'archived' | 'released'): void {
    this.translatePipe.loadTranslations([
      'toast.heading.editPool.released',
      'toast.message.editPool.released',
      'toast.heading.editPool.updated',
      'toast.message.editPool.updated',
      'toast.heading.editPool.archived',
      'toast.message.editPool.archived',
    ])
      .pipe(take(1))
      .subscribe((translations) => {
        if (updateStatus === 'released') {
          this.toast.heading = translations[ 'toast.heading.editPool.released' ];
          this.toastContent = translations[ 'toast.message.editPool.released' ].replace('{0}', poolName);
        } else if (updateStatus === 'updated') {
          this.toast.heading = translations[ 'toast.heading.editPool.updated' ];
          this.toastContent = translations[ 'toast.message.editPool.updated' ].replace('{0}', poolName);
        } else {
          this.toast.heading = translations[ 'toast.heading.editPool.archived' ];
          this.toastContent = translations[ 'toast.message.editPool.archived' ].replace('{0}', poolName);
        }
        this.toast.open();
      });
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreatePoolPayload,
  CreatePoolResponse,
  GetPoolCapacityMetricsParameters,
  GetPoolCapacityMetricsResponse,
  GetPoolDetailsParameters,
  GetPoolDetailsResponse,
  GetPoolNameParameters,
  GetPoolNameResponse,
  GetPoolRegistrationsParameters,
  GetPoolRegistrationsResponse,
  GetPoolsParameters,
  GetPoolsResponse,
  PoolDetails,
  UpdatePoolPayload,
  UpdatePoolResponse,
  UpdatePoolStatusBody,
  UpdatePoolStatusResponse,
  UpdatePoolStatusType
} from 'api/types';
import { createQueryString } from 'utils/create-query-string';
import { PoolsMicroservice } from './microservices/pools.microservice';

/**
 * API service for Pools domain
 */
@Injectable({
  providedIn: 'root'
})
export class PoolsService {
  public constructor(private api: PoolsMicroservice) { }

  private static getUpdatePoolPayload(pool: PoolDetails): UpdatePoolPayload {
    return {
      name: pool.name,
      candidateCode: pool.candidateCode,
      startDate: pool.startDate,
      autorelease: pool.autorelease,
      endDate: pool.endDate,
      daysOfWeek: pool.daysOfWeek,
      daysOfWeekWithRestriction: pool.daysOfWeekWithRestriction,
      dateExceptions: pool.dateExceptions,
      dateAdditions: pool.dateAdditions,
      schedule: pool.schedule,
      scheduleOverrides: pool.scheduleOverrides,
      isRestricted: pool.isRestricted,
      clientIds: pool.clients.map((c) => c.id),
      examIds: pool.exams.map((e) => e.id),
      queueId: pool.queueId,
      applyPoolSettings: pool.applyPoolSettings,
    };
  }

  /**
   * Calls GetPoolName API
   *
   * @param params optional params for get pool name call
   * @returns GetPoolNameResponse Observable
   */
  public getPoolNames(params?: Partial<GetPoolNameParameters>): Observable<GetPoolNameResponse> {
    const queryString = createQueryString({
      startsWith: '',
      ...params
    });
    const path = `pools/names${queryString}`;

    return this.api.get<GetPoolNameResponse>(path);
  }

  /**
   * Calls GetPoolById API
   *
   * @param params params for the GetPoolDetails call
   * @returns GetPoolDetailsResponse Observable
   */
  public getPoolById(params: Partial<GetPoolDetailsParameters>): Observable<GetPoolDetailsResponse> {
    const path = `pools/${params.id}`;

    return this.api.get<GetPoolDetailsResponse>(path);
  }

  public getPoolByIdMinuteWise(params: Partial<GetPoolDetailsParameters>,poolDate : String): Observable<GetPoolDetailsResponse> {
    const path = `pools/${params.id}/occurrence/${poolDate}`;

    return this.api.get<GetPoolDetailsResponse>(path);
  }

  /**
   * Retrieves all registrations for a pool.
   *
   * @param params params for the GetPoolRegistrations call
   * @returns GetPoolRegistrations Observable
   */
  public getPoolRegistrations(params: Partial<GetPoolRegistrationsParameters>):
  Observable<GetPoolRegistrationsResponse> {
    const path = `pools/${params.id}/registrations`;

    return this.api.get<GetPoolRegistrationsResponse>(path);
  }

  /**
   * Calls GetPools API
   *
   * @param params params for the GetPools call
   * @returns GetPoolsResponse Observable
   */
  public getPools(params: GetPoolsParameters): Observable<GetPoolsResponse> {
    const queryString = createQueryString({
      ...params
    });
    const path = `pools${queryString}`;

    return this.api.get<GetPoolsResponse>(path);
  }

  /**
   * Create Pool API call
   *
   * @param payload pool payload
   * @returns observable of CreatePoolResponse
   */
  public createPool(payload: CreatePoolPayload): Observable<CreatePoolResponse> {
    const path = 'pools';
    return this.api.post<CreatePoolResponse, CreatePoolPayload>(path, payload);
  }

  /**
   * Fetches pool capacity metrics for pools that meet the associated criteria
   *
   * @param params pools/capacity-metrics parameters
   * @returns observable to `GetPoolCapacityMetricsResponse`
   */
  public getPoolCapacityMetrics(params: GetPoolCapacityMetricsParameters):
  Observable<GetPoolCapacityMetricsResponse> {
    const queryString = createQueryString({ ...params });
    const path = `pools/capacity-metrics${queryString}`;
    return this.api.get<GetPoolCapacityMetricsResponse>(path);
  }

  /**
   * Updates every property of a pool
   *
   * @param pool full pool object that is transformed to `UpdatePoolPayload`
   * @returns observable to `UpdatePoolResponse`
   */
  public updatePool(pool: PoolDetails): Observable<UpdatePoolResponse> {
    const path = `pools/${pool.id}/poolUpdate`;
    const payload = PoolsService.getUpdatePoolPayload(pool);
    return this.api.patch<UpdatePoolResponse, UpdatePoolPayload>(path, payload);
  }

  public updatePoolMinuteWise(pool: PoolDetails): Observable<UpdatePoolResponse> {
    const path = `pools/${pool.id}/occurrence`;
    const payload = PoolsService.getUpdatePoolPayload(pool);
    return this.api.patch<UpdatePoolResponse, UpdatePoolPayload>(path, payload);
  }


  /**
   * Updates status of the pool to either archive or released 
   *
   * @param id id of the  pool to update
   * @param status new status of the pool
   * @returns observable to `UpdatePoolStatusResponse`
   */
  public updatePoolStatus(id: string, status: UpdatePoolStatusType): Observable<UpdatePoolResponse> {
    const path = `pools/${id}`;
    return this.api.patch<UpdatePoolStatusResponse, UpdatePoolStatusBody>(path, { status });
  }
}
